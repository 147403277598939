/* eslint-disable no-unused-vars */
// LinkComponent.tsx
interface LinkProps {
  href?: string;
  target?: string;
  children?: React.ReactNode;
  className?: string;
  id?: string;
  tabIndex?: number;
  onClick?: (event: any) => void;
  onMouseOver?: (event: any) => void;
  onKeyDown?: (event: any) => void;
  onKeyUp?: (event: any) => void;
  style?: React.CSSProperties;
  role?: string;
  dangerouslySetInnerHTML?: {
    __html: string;
  };
  s_objectid?: string;
}

const Link: React.FC<LinkProps> = ({
  href,
  target,
  children,
  className,
  onClick,
  onMouseOver,
  onKeyDown,
  style,
  ...rest
}) => {
  if (href !== null && href !== undefined) {
    return (
      <a
        href={href}
        target={target}
        className={className}
        onClick={onClick}
        onMouseOver={onMouseOver}
        onKeyDown={onKeyDown}
        style={{ ...style }}
        {...rest}
      >
        {children}
      </a>
    );
  }
  return (
    <span
      className={className}
      onClick={onClick}
      onMouseOver={onMouseOver}
      onKeyDown={onKeyDown}
      style={{ ...style }}
      {...rest}
    >
      {children}
    </span>
  );
};

export default Link;
