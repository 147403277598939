import classes from './input.module.scss';

interface InputProps {
  type: string;
  labelText?: string | React.JSX.Element | React.JSX.Element[];
  placeholder?: string;
  customClass?: string;
  labelForScreenReader?: boolean;
  // eslint-disable-next-line no-unused-vars
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  // eslint-disable-next-line no-unused-vars
  onClickCapture?: (event: React.MouseEvent<HTMLInputElement>) => void;
  // eslint-disable-next-line no-unused-vars
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onBlur?: any;
  value?: string;
  ariaLabel?: string;
  disabled?: boolean;
  id?: string;
  name?: string;
  variant?: 'large' | 'round';
  errorText?: string;
  error?: boolean;
  onFocus?: any;
  datatestid?: string;
  maxLength?: number;
  automationId?: string;
  // eslint-disable-next-line no-unused-vars
  onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  // eslint-disable-next-line no-unused-vars
  handlepaste?: (event: React.ClipboardEvent<HTMLInputElement>) => void;
}

const Input: React.FC<
  React.InputHTMLAttributes<HTMLInputElement> & InputProps
> = (props) => {
  const {
    type,
    labelText,
    placeholder,
    customClass,
    ariaLabel,
    onChange,
    onClickCapture,
    onKeyDown,
    value,
    disabled,
    id,
    name,
    variant,
    errorText,
    error,
    onFocus,
    datatestid,
    maxLength,
    automationId,
    labelForScreenReader = false, // Default value set to false
    onKeyPress,
    handlepaste,
    ...rest
  } = props;

  const customclass = `${error ? classes.error : ''}`;
  const inputVariant = `${variant ? classes[variant] : ''}`;

  return (
    <div
      className={`${classes.inputFieldControl} ${customclass} ${inputVariant} ${customClass}`}
    >
      {labelText && (
        <label
          htmlFor={id}
          className={labelForScreenReader ? 'visually-hidden' : ''}
        >
          {labelText}
        </label>
      )}{' '}
      <input
        automation-id={automationId}
        type={type}
        disabled={disabled}
        className="form-control"
        placeholder={placeholder}
        id={id}
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
        name={name}
        aria-label={ariaLabel}
        onKeyPress={onKeyPress}
        onFocus={onFocus}
        onClickCapture={onClickCapture}
        onPaste={handlepaste}
        data-testid={datatestid}
        maxLength={maxLength ? maxLength : undefined}
        {...rest}
      />
      <i className="fa-solid fa-magnifying-glass"></i>
      {errorText && error && <p>{errorText}</p>}
    </div>
  );
};

export default Input;
